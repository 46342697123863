import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';
import { AuthenticationService } from './firebase-authentication.service';
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    id;
    constructor(
        private auth: AuthenticationService,
        private firestore: FirestoreService
    ) {}

    updateToken(id) {
        this.id = id;
    }

    login(username: string, password: string): Observable<any> {
        return new Observable((observer) => {
            this.auth
                .login(username, password)
                .then((user) => {
                    observer.next({ id: user.uid });
                })
                .catch((err) => {
                    observer.error(err);
                });
        });
    }

    getUser(): Observable<any> {
        console.log('Get User -> ', this.id);
        return this.addIdToObject(
            this.id,
            this.firestore.getOne('admins', this.id)
        );
    }

    addIdToObject(id, obj: Observable<any>) {
        return new Observable((observer) => {
            if (id) {
                obj.subscribe(
                    (ref) => {
                        const newObj = ref;
                        if (newObj) {
                            newObj.id = id;
                        }
                        observer.next(newObj);
                    },
                    (err) => {
                        observer.error(err);
                    }
                );
            } else {
                observer.error({ message: 'No ID' });
            }
        });
    }

    signUp(user): Observable<any> {
        const userInfo = {
            id: null,
            email: user.email,
            userType: user.userType,
        };

        return new Observable((observer) => {
            this.auth
                .createAccount(user.email, user.password)
                .then((user: any) => {
                    userInfo.id = user.uid;
                    this.firestore.createWithId('admins', userInfo).then(
                        (usr) => {
                            observer.next(userInfo);
                        },
                        (err) => {
                            observer.error(err);
                        }
                    );
                })
                .catch((err) => {
                    observer.error(err);
                });
        });
    }

    logout() {
        return this.auth.logout();
    }
}
