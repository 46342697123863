import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserProviderService } from 'src/app/services/userProvider/user-provider.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
})
export class AuthComponent implements OnInit {
    showPassword = false;
    loginMode = true;
    authSpinner = false;
    errMsg: string = '';

    constructor(
        private authService: AuthService,
        private userProvider: UserProviderService,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (this.userProvider.loggedInUser.id) {
            this.router.navigate(['/posts']);
        }
    }

    onSubmit(form: NgForm) {
        const email = form.value.email;
        const password = form.value.password;

        this.authSpinner = true;
        this.errMsg = null;

        this.authService.login(email, password).subscribe(
            (res) => {
                this.logUserIn(res);
            },
            (err) => {
                this.errMsg = err.message;
                this.authSpinner = false;

                console.log('Err -> ', err);
            }
        );
    }

    logUserIn(userData) {
        console.log('logUserIn -> ', userData);
        this.userProvider.setToken(userData.id).then(
            () => {
                this.userProvider.load().then(
                    () => {
                        this.router.navigate(['/posts']);
                        this.authSpinner = false;
                    },
                    (err) => {
                        this.authSpinner = false;
                    }
                );
            },
            (err) => {
                this.authSpinner = false;
            }
        );
    }
}
