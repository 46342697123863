<nav
    id="sidebar"
    [ngClass]="sideBarStatus ? 'active' : ''"
    *ngIf="userProvider.loggedInUser.id"
>
    <div id="logo">
        <img src="assets/img/logo-notext.png" alt="" />
        <h1>tripcliq</h1>
    </div>
    <div class="divider"></div>
    <ul class="list-unstyled components">
        <li
            routerLinkActive="active"
            *ngIf="userProvider.loggedInUser.userType != 'vendor'"
        >
            <div class="fa-icon">
                <fa-icon [icon]="faList"></fa-icon>
            </div>
            <a routerLink="/posts">Locations</a>
        </li>

        <li id="logout">
            <a (click)="logout()">Logout</a>
        </li>
    </ul>
</nav>
