import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProviderService } from 'src/app/services/userProvider/user-provider.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private userProvider: UserProviderService,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        let url: string = state.url;
        return this.checkUserLogin(next, url);
    }
    checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
        if (this.userProvider.loggedInUser.id) {
            if (
                route.data.role &&
                route.data.role.length > 0 &&
                !route.data.role.find(
                    (role) => role == this.userProvider.loggedInUser.userType
                )
            ) {
                this.router.navigate(['/dashboard']);
                return false;
            }
            return true;
        }

        this.router.navigate(['/home']);
        return false;
    }
}
