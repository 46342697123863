import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from './services/util/util.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @Input() sideMenuState;
    title = 'levelupnow-admin';

    constructor(public util: UtilService) {}

    ngOnInit() {}
}
