<div class="row">
    <div class="col-6 offset-3">
        <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
            <div class="form-group">
                <label for="email">E-Mail</label>
                <input
                    type="email"
                    id="email"
                    class="form-control"
                    ngModel
                    name="email"
                    required
                    email
                />
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input
                    type="password"
                    id="password"
                    class="form-control"
                    ngModel
                    name="password"
                    required
                    minlength="5"
                />
            </div>
            <div>
                <p style="color: red">{{ errMsg }}</p>
            </div>
            <div>
                <button
                    class="btn btn-primary col-12"
                    [disabled]="!authForm.valid"
                >
                    Login
                </button>
                <div class="row" *ngIf="authSpinner">
                    <div class="col-12 text-center">
                        <div style="width: 200px; margin: 0 auto">
                            <div class="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
