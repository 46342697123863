import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable()
export class StorageService {
    constructor(
        private storage: AngularFireStorage,
        public baseFs: FirestoreService
    ) {}

    public uploadContent(file: any, fileName: any): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                if (file) {
                    return this.storage
                        .upload(`videos/thumbnails/${fileName}`, file)
                        .then(
                            (success) => {
                                return this.storage
                                    .ref(`videos/thumbnails/${fileName}`)
                                    .getDownloadURL()
                                    .subscribe((url) => {
                                        resolve({ url, fileName });
                                    });
                            },
                            (failure) => {
                                reject(failure);
                            }
                        )
                        .catch((err) => {
                            reject(err);
                        });
                } else {
                    reject(new Error(' choice key not given'));
                }
            } catch (e) {
                reject(e);
            }
        });
    }
}
