import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { take } from 'rxjs/operators';
import { BaseDatabaseModel } from './base-dto.model';

@Injectable()
export class FirestoreService {
    constructor(public store: AngularFirestore) {}

    public createWithId<T extends BaseDatabaseModel>(
        collection: string,
        data: T
    ): Promise<void> {
        console.log('DATA -> ', data);
        return this.store
            .doc<T>(`${collection}/${data.id}`)
            .set(this.addCreatedAt(data));
    }

    public update<T extends BaseDatabaseModel>(
        collection: string,
        id: string,
        document: Partial<T>
    ): Promise<void> {
        return this.store
            .doc<T>(`${collection}/${id}`)
            .update(this.addUpdatedAt(document));
    }

    public batchUpdate(documents: []): Promise<void> {
        let batch = firebase.firestore().batch();
        documents.forEach((document: any, index) => {
            const docRef = firebase
                .firestore()
                .collection('meals')
                .doc(document.id);
            batch.update(docRef, {
                order: index,
            });
        });

        return batch.commit();
    }

    public getOne<T extends BaseDatabaseModel>(
        collection: string,
        id: string
    ): Observable<T> {
        return this.store
            .doc<T>(`${collection}/${id}`)
            .valueChanges()
            .pipe(take(1));
    }

    public find<T extends BaseDatabaseModel>(
        collection: string,
        queryFn: QueryFn
    ): Observable<T[]> {
        return this.store
            .collection<T>(collection, queryFn)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    public async create<T extends BaseDatabaseModel>(
        collection: string,
        data: T
    ): Promise<
        firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    > {
        const doc = await this.store
            .collection<T>(collection)
            .add(this.addCreatedAt(data));
        return doc.get();
    }

    addCreatedAt(data) {
        return {
            ...data,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
    }

    addUpdatedAt(data) {
        return {
            ...data,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
    }
}
