import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const appRoutes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/posts',
            },
            {
                path: 'posts',
                data: {
                    role: ['admin', 'superAdmin'],
                },
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/posts/posts.module').then(
                        (m) => m.PostsModule
                    ),
            },
            {
                path: 'posts/edit/:id',
                data: {
                    role: ['admin', 'superAdmin'],
                },
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/posts/add/add.module').then(
                        (m) => m.AddModule
                    ),
            },
            {
                path: 'posts/edit',
                data: {
                    role: ['admin', 'superAdmin'],
                },
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/posts/add/add.module').then(
                        (m) => m.AddModule
                    ),
            },
            {
                path: 'dashboard',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
            },
        ],
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('./auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: '**',
        redirectTo: '/auth',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
