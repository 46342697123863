import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class UserProviderService {
    public loggedInUser;
    isAdmin: boolean = true;

    constructor(private authService: AuthService) {
        this.createNewEmptyUser();
    }

    createNewEmptyUser() {
        this.loggedInUser = {
            id: null,
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            token: '',
            profileImage: '',
        };
    }

    async setToken(token) {
        this.authService.updateToken(token);
        await localStorage.setItem('tripCliqAdminToken', token);
    }

    async setLoggedInUser(user) {
        Object.assign(this.loggedInUser, user);
        if (user.id) {
            await localStorage.setItem('tripCliqAdminId', user.id);
        }
        this.loggedInUser.token = await this.getToken();
        if (this.loggedInUser.userType == 'superAdmin') {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }
        console.log('loggedInUser ----> ', this.loggedInUser);
    }

    async getToken() {
        const token = await localStorage.getItem('tripCliqAdminToken');
        return token;
    }

    load() {
        console.log('Loading');
        return new Promise((resolve, reject) => {
            this.getToken().then((token) => {
                this.authService.updateToken(token);
                this.authService.getUser().subscribe(
                    (user: any) => {
                        console.log('User -> ', user);
                        if (user) {
                            this.setLoggedInUser(user);
                            resolve(true);
                        }
                    },
                    (err) => {
                        resolve(true);
                        console.log(err);
                    }
                );
            });
        });
    }

    async logout(): Promise<any> {
        this.createNewEmptyUser();
        await this.authService.logout();
        localStorage.removeItem('tripCliqAdminToken');
        localStorage.removeItem('tripCliqAdminId');
    }
}
