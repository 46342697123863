import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthComponent } from './auth/auth.component';
import { FormsModule } from '@angular/forms';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RouterModule } from '@angular/router';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './services/auth/firebase-authentication.service';
import { StorageService } from './services/api/firestorage.service';
import { FirestoreService } from './services/firestore/firestore.service';
import { UserProviderService } from './services/userProvider/user-provider.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        AppComponent,
        SideMenuComponent,
        AuthComponent,
        DashboardComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        HttpClientJsonpModule,
        FontAwesomeModule,
        AppRoutingModule,
        RouterModule,
        FormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBSxdB8HTSyX5yTrGQmtTTYoC-50wrKHNc',
        }),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFireStorageModule,
        BrowserAnimationsModule,
    ],
    providers: [
        FirestoreService,
        StorageService,
        AuthenticationService,

        {
            provide: APP_INITIALIZER,
            useFactory: initUserProviderFactory,
            deps: [UserProviderService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
export function initUserProviderFactory(provider: UserProviderService) {
    return () => provider.load();
}
