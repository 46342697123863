import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faList, faVideo } from '@fortawesome/free-solid-svg-icons';
import { UserProviderService } from 'src/app/services/userProvider/user-provider.service';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
    isLoggedin;
    sideBarStatus: boolean = false;

    faList = faList;
    faVideo = faVideo;

    constructor(
        public userProvider: UserProviderService,
        private router: Router
    ) {}

    ngOnInit(): void {}

    logout() {
        this.userProvider.logout().then(() => {
            this.router.navigate(['/auth']);
        });
    }
}
